import React from 'react'
import { connect } from 'react-redux'
import { Segment, Header, Grid, Image, List, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import { Link } from '../../locales'
import { filtersActions } from '../Filters/state'
import { modalActions } from '../Modals/state'
import { navigationActions } from './state'
import { unescape } from '../../utils/helpers'
import WishlistPopup from '../Wishlist/WishlistPopup'
import SearchModal from '../Modals/SearchModal'

import logoDE from '../../assets/tischler-logo.jpg'

const NavMobile = props => {
  const {
    subMenuName,
    mobileMenuActive,
    setSubNavigation,
    toggleMobileMenu,
    resetFilters,
    t,
    i18n,
    countries,
    categories,
    wpPages,
    travelTypes,
    categoryTypes,
    toggleModal
  } = props

  function handleSubNavigation (e, name) {
    e.preventDefault()
    return setSubNavigation({ subMenuName: !subMenuName ? name : '' })
  }

  function handleCategoryNavigation () {
    resetFilters()
    return toggleMobileMenu()
  }

  function handleMenuClose () {
    return toggleMobileMenu()
  }

  const searchModalId = 'searchModal'

  const MenuHeader = () => (
    <Grid padded verticalAlign='middle'>
      <Grid.Column width={4} textAlign='left'>
        <i
          className={`icon big ${mobileMenuActive ? 'ta-close' : 'ta-menu'}`}
          onClick={handleMenuClose}
        />
      </Grid.Column>

      <Grid.Column
        width={8}
        textAlign='center'
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Link to='/' lang={i18n.language}>
          <Image
            loading='eager'
            fadeIn={false}
            size='small'
            src={logoDE}
            alt='tischler logo'
            height='62px'
            width='62px'
            centered
            style={{ height: '78px', width: 'auto' }}
          />
        </Link>
      </Grid.Column>

      <Grid.Column width={4} textAlign='right'>
        <Icon.Group>
          <i
            title='Search'
            className='icon big link fitted ta-search-thin'
            onClick={() => toggleModal(searchModalId)}
          />
        </Icon.Group>

        <WishlistPopup />
      </Grid.Column>
    </Grid>
  )

  return (
    <div className='nav-mobile'>
      <MenuHeader />
      {mobileMenuActive && (
        <div className='mobile-menu'>
          <div className='mobile-header'>
            <MenuHeader />
            <SearchModal id={searchModalId} />
          </div>
          <div className='mobile-links'>
            <Segment basic className={`${subMenuName ? 'hide' : 'show'}`}>
              <List divided size='huge'>
                <List.Item
                  as='a'
                  onClick={e => handleSubNavigation(e, 'destinations')}
                  className='arrow2'
                >
                  {t('nav-main-mobile.destination')}
                </List.Item>
                <List.Item
                  as='a'
                  onClick={e => handleSubNavigation(e, 'reisearten')}
                  className='arrow2'
                >
                  {t('nav-main-mobile.type')}
                </List.Item>
                <List.Item
                  as={Link}
                  lang={i18n.language}
                  to='/wishlist'
                  onClick={handleMenuClose}
                >
                  {t('nav-main-mobile.wishlist')}
                </List.Item>
                <List.Item
                  as='a'
                  onClick={e => handleSubNavigation(e, 'about')}
                  className='arrow2'
                >
                  {t('nav-main-mobile.about')}
                </List.Item>

                {wpPages
                  .filter(
                    ({ node: { acf, slug } }) =>
                      acf &&
                      acf.menu_group === 'main' &&
                      slug !== 'kontakt' &&
                      slug !== 'contact'
                  )
                  .map(
                    ({
                      node: {
                        id,
                        slug,
                        title,
                        acf: { alt_nav_title }
                      }
                    }) => (
                      <List.Item
                        key={id}
                        as={Link}
                        lang={i18n.language}
                        to={`/${slug}`}
                        onClick={handleMenuClose}
                      >
                        {unescape(alt_nav_title) || unescape(title)}
                      </List.Item>
                    )
                  )}

                <List.Item
                  as={Link}
                  lang={i18n.language}
                  to='/contact'
                  name='contact'
                  style={{ marginTop: 3 }}
                  onClick={handleMenuClose}
                >
                  {t('nav-main-mobile.contact')}
                </List.Item>

                {wpPages
                  .filter(
                    ({ node: { acf } }) => acf && acf.top_menu === 'top-menu'
                  )
                  .map(
                    ({
                      node: {
                        id,
                        slug,
                        title,
                        acf: { alt_nav_title }
                      }
                    }) => (
                      <List.Item key={id}>
                        <Link
                          to={`/${slug}`}
                          lang={i18n.language}
                          onClick={handleMenuClose}
                        >
                          {unescape(alt_nav_title) || unescape(title)}
                        </Link>
                      </List.Item>
                    )
                  )}
              </List>
            </Segment>

            <Segment
              basic
              className={`${subMenuName === 'destinations' ? 'show' : 'hide'}`}
            >
              <List divided size='huge'>
                <List.Item
                  onClick={e => handleSubNavigation(e)}
                  className='link-back'
                >
                  <List.Header>{t('nav-main-mobile.back')}</List.Header>
                </List.Item>
                <List.Item>
                  <Header color='grey' sub>
                    {t('nav-main-mobile.find')}
                  </Header>
                </List.Item>
                {Object.keys(countries).map((category, index) => (
                  <>
                    <List.Item
                      key={index}
                      as={Link}
                      lang={i18n.language}
                      to={`/country-regions/all/destinations/${category}`}
                      onClick={handleMenuClose}
                      className='category'
                    >
                      {category}
                    </List.Item>
                    {countries[category].map(
                      ({ node: { id, title, slug, countryRegionSlug } }) => (
                        <List.Item
                          key={id}
                          as={Link}
                          lang={i18n.language}
                          to={`/country-regions/${countryRegionSlug}/destinations/${slug}`}
                          onClick={handleMenuClose}
                          className='arrow1'
                        >
                          {title}
                        </List.Item>
                      )
                    )}
                  </>
                ))}
                <List.Item>
                  <Header color='grey' sub>
                    {t('nav-main-mobile.info')}
                  </Header>
                </List.Item>
                <List.Item
                  as={Link}
                  lang={i18n.language}
                  to='/countries'
                  onClick={handleMenuClose}
                  className='arrow1'
                >
                  {t('nav-main.land-info')}
                </List.Item>
                <List.Item
                  as={Link}
                  lang={i18n.language}
                  to='/entry'
                  onClick={handleMenuClose}
                  className='arrow1'
                >
                  {t('nav-main.requirements')}
                </List.Item>
              </List>
            </Segment>

            <Segment
              basic
              className={`${subMenuName === 'reisearten' ? 'show' : 'hide'}`}
            >
              <List divided size='huge'>
                <List.Item
                  onClick={e => handleSubNavigation(e)}
                  className='link-back'
                >
                  <List.Header>{t('nav-main-mobile.back')}</List.Header>
                </List.Item>
                {travelTypes.map(({ title, service }) => {
                  return categoryTypes.map(ct => (
                    <React.Fragment key={ct.type}>
                      <List.Item>
                        <Header color='grey' sub>
                          {title} {ct.title}
                        </Header>
                      </List.Item>
                      {categories
                        .filter(
                          ({ node }) =>
                            node.service === service && node.type === ct.type
                        )
                        .map(({ node: { id, title } }) => (
                          <List.Item
                            key={id}
                            as={Link}
                            lang={i18n.language}
                            to={`/country-regions/all/destinations/all/${service}?${
                              ct.type === 'Themen' ? 'categories' : service
                            }=${title}`}
                            state={{
                              service,
                              [ct.type === 'Themen'
                                ? 'sharedCategories'
                                : `${service}Categories`]: title
                            }}
                            onClick={handleCategoryNavigation}
                            className='arrow1'
                          >
                            {title}
                          </List.Item>
                        ))}
                    </React.Fragment>
                  ))
                })}
              </List>
            </Segment>

            <Segment
              basic
              className={`${subMenuName === 'about' ? 'show' : 'hide'}`}
            >
              <List divided size='huge'>
                <List.Item
                  onClick={e => handleSubNavigation(e)}
                  className='link-back'
                >
                  <List.Header>{t('nav-main-mobile.back')}</List.Header>
                </List.Item>

                <List.Item>
                  <Header color='grey' sub>
                    {t('nav-main.about-us')}
                  </Header>
                </List.Item>
                {wpPages
                  .filter(
                    ({ node: { acf } }) =>
                      acf &&
                      acf.menu_group === 'uber-uns' &&
                      acf.menu_section === 'uber-uns'
                  )
                  .map(
                    ({
                      node: {
                        id,
                        slug,
                        title,
                        acf: { alt_nav_title }
                      }
                    }) => (
                      <List.Item
                        key={id}
                        as={Link}
                        lang={i18n.language}
                        to={`/${slug}`}
                        onClick={handleMenuClose}
                        className='arrow1'
                      >
                        {unescape(alt_nav_title) || unescape(title)}
                      </List.Item>
                    )
                  )}

                <List.Item>
                  <Header color='grey' sub>
                    {t('nav-main.service')}
                  </Header>
                </List.Item>
                {wpPages
                  .filter(
                    ({ node: { acf } }) =>
                      acf &&
                      acf.menu_group === 'uber-uns' &&
                      acf.menu_section === 'service'
                  )
                  .map(
                    ({
                      node: {
                        id,
                        slug,
                        title,
                        acf: { alt_nav_title }
                      }
                    }) => (
                      <List.Item
                        key={id}
                        as={Link}
                        lang={i18n.language}
                        to={`/${slug}`}
                        onClick={handleMenuClose}
                        className='arrow1'
                      >
                        {unescape(alt_nav_title) || unescape(title)}
                      </List.Item>
                    )
                  )}

                <List.Item>
                  <Header color='grey' sub>
                    {t('nav-main.help')}
                  </Header>
                </List.Item>
                {/* <List.Item
                  as={Link}
                  lang={i18n.language}
                  to='/blog'
                  onClick={handleMenuClose}
                  className='arrow1'
                >
                  {t('nav-main-mobile.reports')}
                </List.Item> */}
                {wpPages
                  .filter(
                    ({ node: { acf } }) =>
                      acf &&
                      acf.menu_group === 'uber-uns' &&
                      acf.menu_section === 'hilfreiches'
                  )
                  .map(
                    ({
                      node: {
                        id,
                        slug,
                        title,
                        acf: { alt_nav_title }
                      }
                    }) => (
                      <List.Item
                        key={id}
                        as={Link}
                        lang={i18n.language}
                        to={`/${slug}`}
                        onClick={handleMenuClose}
                        className='arrow1'
                      >
                        {unescape(alt_nav_title) || unescape(title)}
                      </List.Item>
                    )
                  )}
              </List>
            </Segment>
          </div>

          <div className='mobile-footer'>
            <Grid verticalAlign='middle' padded>
              <Grid.Column width={7}>
                <a href='https://www.facebook.com/tischlerreisen'>
                  <Icon
                    style={{ width: '1em' }}
                    className='ta-facebook'
                    color='red'
                    size='big'
                  />
                </a>
                <a href='https://www.instagram.com/tischlerreisen/'>
                  <Icon
                    style={{ width: '1em' }}
                    className='ta-instagram'
                    color='red'
                    size='big'
                  />
                </a>
              </Grid.Column>
              <Grid.Column width={9} textAlign='right'>
                <List floated='right'>
                  <List.Item>
                    <Icon
                      style={{ width: '1em', margin: 0, padding: 0 }}
                      className='ta-phone'
                      color='red'
                      size='big'
                    />
                    <List.Content style={{ margin: 0, padding: 0 }}>
                      {t('nav-main-mobile.days')}:<br />
                      10.00-17.00 {t('nav-main-mobile.hour')}
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      )}
    </div>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ navigation }) => {
  return {
    subMenuName: navigation.subMenuName,
    mobileMenuActive: navigation.mobileMenuActive
  }
}

const mapDispatchToProps = {
  setSubNavigation: navigationActions.setSubNavigation,
  toggleMobileMenu: navigationActions.toggleMobileMenu,
  filterToggleItem: filtersActions.filterToggleItem,
  resetFilters: filtersActions.resetFilters,
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NavMobile)
)
