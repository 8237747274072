import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Grid,
  Menu,
  Header,
  Image,
  Segment
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import { Link } from '../../locales'
import { navigationActions } from './state'
import { modalActions } from '../Modals/state'
import { filtersActions } from '../Filters/state'
import { unescape } from '../../utils/helpers'
import WishlistPopup from '../Wishlist/WishlistPopup'
import SearchModal from '../Modals/SearchModal'

import logoDE from '../../assets/tischler-logo.jpg'

class NavDesktop extends Component {
  state = {
    menu: '',
    logoClass: 'logo-big'
  }

  showMenu = menuName => {
    this.setState({ menu: menuName })
    document.addEventListener('click', this.closeMenu)
  }

  closeMenu = event => {
    document.removeEventListener('click', this.closeMenu)

    if (
      event.path &&
      event.path[5] &&
      event.path[5].className === 'ui equal width grid'
    ) {
      this.setState(null, () => {})
    } else {
      event.preventDefault()
      this.setState({ menu: '' })
    }
  }

  changeLogoClass = logoClass => {
    if (this.state.logoClass !== logoClass) {
      this.setState({ logoClass: logoClass })
    }
  }

  // Logo adjust based on scroll
  componentDidMount () {
    document.addEventListener('scroll', this.trackScrolling)
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = () => {
    if (window.pageYOffset === 0) {
      this.changeLogoClass('logo-big')
    } else {
      this.changeLogoClass('logo-small')
    }
  }

  render () {
    const {
      toggleModal,
      resetFilters,
      t,
      i18n,
      countries,
      categories,
      wpPages,
      travelTypes,
      categoryTypes
    } = this.props

    const { menu } = this.state
    const searchModalId = 'searchModal'

    return (
      <div className='nav-desktop' id='headerDesktop'>
        {/* TOP MENU */}
        <Menu className='top-menu' text>
          <Container>
            <Menu.Menu position='right'>
              {wpPages
                .filter(
                  ({ node: { acf } }) => acf && acf.top_menu === 'top-menu'
                )
                .map(
                  ({
                    node: {
                      id,
                      slug,
                      title,
                      acf: { alt_nav_title }
                    }
                  }) => (
                    <Menu.Item key={id} className='wp-page'>
                      <Link to={`/${slug}`} lang={i18n.language}>
                        {unescape(alt_nav_title) || unescape(title)}
                      </Link>
                    </Menu.Item>
                  )
                )}

              <Menu.Item name='search' fitted>
                <i
                  title='Search'
                  className='icon big link fitted ta-search-thin'
                  onClick={() => toggleModal(searchModalId)}
                />
              </Menu.Item>
              <Menu.Item name='wishlist' fitted>
                <WishlistPopup />
              </Menu.Item>
              <Menu.Item
                name='phone'
                className='phone'
                as='a'
                href={`tel:${t('nav-main.phone')}`}
              >
                <i className='icon big fitted ta-phone' /> {t('nav-main.phone')}
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>

        {/* MAIN MENU */}
        <Menu borderless text size='huge'>
          <Container>
            <Menu.Item name='logo' fitted='vertically'>
              <Link to='/' lang={i18n.language}>
                <Grid>
                  <Grid.Column
                    only='computer'
                    style={{ paddingTop: 0, paddingBottom: 0, width: '130px' }}
                  >
                    <Image
                      size='small'
                      src={logoDE}
                      loading='eager'
                      fadeIn={false}
                      alt='tischler logo'
                      className={this.state.logoClass}
                      height='62px'
                      width='62px'
                      style={{
                        width: 'auto',
                        position: 'fixed'
                      }}
                    />
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column
                    only='tablet mobile'
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Image
                      size='mini'
                      src={logoDE}
                      alt='tischler logo'
                      style={{ height: '78px', width: 'auto' }}
                    />
                  </Grid.Column>
                </Grid>
              </Link>
            </Menu.Item>
            <Menu.Item
              as={'a'}
              lang={i18n.language}
              partially
              name='destinations'
              active={menu === 'destinations'}
              onClick={() => this.showMenu('destinations')}
            >
              {t('nav-main.destination')} <i className='icon ta-arrow-2-down' />
            </Menu.Item>
            <Menu.Item
              as={'a'}
              lang={i18n.language}
              noActive
              name='reisearten'
              active={menu === 'reisearten'}
              onClick={() => this.showMenu('reisearten')}
            >
              {t('nav-main.trip-type')} <i className='icon ta-arrow-2-down' />
            </Menu.Item>
            <Menu.Item
              as={Link}
              lang={i18n.language}
              to='/wishlist'
              name='wishlist'
              style={{ marginTop: 3 }}
            >
              {t('nav-main.wishlist')}
            </Menu.Item>
            <Menu.Item
              as={'a'}
              lang={i18n.language}
              noActive
              name='about'
              active={menu === 'about'}
              onClick={() => this.showMenu('about')}
            >
              {t('nav-main.about')} <i className='icon ta-arrow-2-down' />
            </Menu.Item>
            {wpPages
              .filter(
                ({ node: { acf, slug } }) =>
                  acf &&
                  acf.menu_group === 'main' &&
                  slug !== 'kontakt' &&
                  slug !== 'contact'
              )
              .map(({ node: { id, slug, title, acf: { alt_nav_title } } }) => (
                <Menu.Item
                  as={Link}
                  lang={i18n.language}
                  to={`/${slug}`}
                  key={id}
                  name='slug'
                  style={{ marginTop: 3 }}
                >
                  {unescape(alt_nav_title) || unescape(title)}
                </Menu.Item>
              ))}
            <Menu.Item
              as={Link}
              lang={i18n.language}
              to='/contact'
              name='contact'
              style={{ marginTop: 3 }}
            >
              {t('nav-main.contact-button')}
            </Menu.Item>
          </Container>
        </Menu>

        {/* SUBMENU ITEMS - Reiseziele */}
        <div
          className={`extended-menu ${
            menu === 'destinations' ? 'open' : 'closed'
          }`}
        >
          <Container>
            <Segment basic>
              <Grid>
                <Grid.Row style={{ backgroundColor: '#ededed' }}>
                  <Grid.Column width={10}>
                    <Header as='h3' dividing color='red'>
                      {t('nav-main.find')}
                    </Header>
                    <Grid>
                      <Grid.Row columns={4}>
                        {Object.keys(countries).map((category, index) => (
                          <Grid.Column key={index}>
                            <ul className='sub-menu'>
                              <li className='no-bullet'>{category}</li>
                              {countries[category].map(
                                ({
                                  node: { id, title, slug, countryRegionSlug }
                                }) => (
                                  <li key={id} style={{ padding: 0 }}>
                                    {' '}
                                    <Link
                                      to={`/country-regions/${countryRegionSlug}/destinations/${slug}`}
                                      lang={i18n.language}
                                    >
                                      {title}
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Header as='h3' dividing color='red'>
                      {t('nav-main.info')}
                    </Header>
                    <ul className='sub-menu'>
                      <li>
                        <Link to='/countries' lang={i18n.language}>
                          {t('nav-main.land-info')}
                        </Link>
                      </li>
                      <li>
                        <Link to='/entry' lang={i18n.language}>
                          {t('nav-main.requirements')}
                        </Link>
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  textAlign='center'
                  style={{ backgroundColor: '#ededed' }}
                >
                  <Grid.Column>
                    <i className='icon link grey big ta-arrow-2-up' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>

        {/* SUBMENU ITEMS - Reisearten */}
        <div
          className={`extended-menu menu-categories ${
            menu === 'reisearten' ? 'open' : 'closed'
          }`}
        >
          <Container>
            <Segment basic>
              <Grid columns='equal'>
                <Grid.Row style={{ backgroundColor: '#ededed' }}>
                  {travelTypes.map(({ title, service }) => (
                    <Grid.Column key={service}>
                      <Header as='h3' dividing color='red'>
                        <Link
                          to={`/country-regions/all/destinations/all/${service}`}
                          state={{ service }}
                          lang={i18n.language}
                        >
                          <div onClick={() => resetFilters()}>{title}</div>
                        </Link>
                      </Header>
                      <Grid columns='two'>
                        {categoryTypes.map(({ type, title }) => (
                          <Grid.Column key={type}>
                            <Header as='h4' color='grey'>
                              {title}
                            </Header>
                            <ul className='sub-menu'>
                              {categories
                                .filter(
                                  ({ node }) =>
                                    node.service === service &&
                                    node.type === type
                                )
                                .map(({ node: { id, title } }) => (
                                  <li key={id}>
                                    <Link
                                      to={`/country-regions/all/destinations/all/${service}?${
                                        type === 'Themen'
                                          ? 'categories'
                                          : service
                                      }=${encodeURIComponent(title)}`}
                                      state={{
                                        service,
                                        [type === 'Themen'
                                          ? 'sharedCategories'
                                          : `${service}Categories`]: title
                                      }}
                                      lang={i18n.language}
                                    >
                                      <span onClick={() => resetFilters()}>
                                        {title}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </Grid.Column>
                        ))}
                      </Grid>
                    </Grid.Column>
                  ))}
                  <Grid.Column color='red'>
                    <Header as='h3' dividing inverted>
                      {t('nav-main.sidebar.header')}
                    </Header>
                    <p>{t('nav-main.sidebar.content')}</p>
                    <p>
                      <strong>
                        {t('nav-main.sidebar.email-label')}{' '}
                        <a
                          href={`mailto:${t('nav-main.sidebar.email')}`}
                          className='white-link'
                        >
                          {t('nav-main.sidebar.email')}
                        </a>
                        <br />
                        {t('nav-main.sidebar.phone-label')}{' '}
                        <a
                          href={`tel:${t('nav-main.sidebar.phone')}`}
                          className='white-link'
                        >
                          {t('nav-main.sidebar.phone')}
                        </a>
                      </strong>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  textAlign='center'
                  style={{ backgroundColor: '#ededed' }}
                >
                  <Grid.Column width={12}>
                    <i className='icon link grey big ta-arrow-2-up' />
                  </Grid.Column>
                  <Grid.Column color='red' width={4} />
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>

        {/* SUBMENU ITEMS - Über uns / Service */}
        <div
          className={`extended-menu ${menu === 'about' ? 'open' : 'closed'}`}
        >
          <Container>
            <Segment basic>
              <Grid columns='equal'>
                <Grid.Row style={{ backgroundColor: '#ededed' }}>
                  <Grid.Column width={4}>
                    <Header as='h3' dividing color='red'>
                      {t('nav-main.about-us')}
                    </Header>
                    <ul className='sub-menu'>
                      {wpPages
                        .filter(
                          ({ node: { acf } }) =>
                            acf &&
                            acf.menu_group === 'uber-uns' &&
                            acf.menu_section === 'uber-uns'
                        )
                        .map(
                          ({
                            node: {
                              id,
                              slug,
                              title,
                              acf: { alt_nav_title }
                            }
                          }) => (
                            <li key={id}>
                              <Link to={`/${slug}`} lang={i18n.language}>
                                {unescape(alt_nav_title) || unescape(title)}
                              </Link>
                            </li>
                          )
                        )}
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h3' dividing color='red'>
                      {t('nav-main.service')}
                    </Header>
                    <ul className='sub-menu'>
                      {wpPages
                        .filter(
                          ({ node: { acf } }) =>
                            acf &&
                            acf.menu_group === 'uber-uns' &&
                            acf.menu_section === 'service'
                        )
                        .map(
                          ({
                            node: {
                              id,
                              slug,
                              title,
                              acf: { alt_nav_title }
                            }
                          }) => (
                            <li key={id}>
                              <Link to={`/${slug}`} lang={i18n.language}>
                                {unescape(alt_nav_title) || unescape(title)}
                              </Link>
                            </li>
                          )
                        )}
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h3' dividing color='red'>
                      {t('nav-main.help')}
                    </Header>
                    <ul className='sub-menu'>
                      {/* <li>
                        <Link to='/blog' lang={i18n.language}>
                          {t('nav-main.sidebar.reports')}
                        </Link>
                      </li> */}
                      {wpPages
                        .filter(
                          ({ node: { acf } }) =>
                            acf &&
                            acf.menu_group === 'uber-uns' &&
                            acf.menu_section === 'hilfreiches'
                        )
                        .map(
                          ({
                            node: {
                              id,
                              slug,
                              title,
                              acf: { alt_nav_title }
                            }
                          }) => (
                            <li key={id}>
                              <Link to={`/${slug}`} lang={i18n.language}>
                                {unescape(alt_nav_title) || unescape(title)}
                              </Link>
                            </li>
                          )
                        )}
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={4} className='red'>
                    <Header as='h3' dividing inverted>
                      {t('nav-main.sidebar.header')}
                    </Header>
                    <p>{t('nav-main.sidebar.content')}</p>
                    <p>
                      <strong>
                        {t('nav-main.sidebar.email-label')}{' '}
                        <a
                          href={`mailto:${t('nav-main.sidebar.email')}`}
                          className='white-link'
                        >
                          {t('nav-main.sidebar.email')}
                        </a>
                        <br />
                        {t('nav-main.sidebar.phone-label')}{' '}
                        <a
                          href={`tel:${t('nav-main.sidebar.phone')}`}
                          className='white-link'
                        >
                          {t('nav-main.sidebar.phone')}
                        </a>
                      </strong>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  textAlign='center'
                  style={{ backgroundColor: '#ededed' }}
                >
                  <Grid.Column width={12}>
                    <i className='icon link grey big ta-arrow-2-up' />
                  </Grid.Column>
                  <Grid.Column color='red' width={4} />
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>

        <SearchModal id={searchModalId} />
      </div>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/

const mapDispatchToProps = {
  setSubNavigation: navigationActions.setSubNavigation,
  toggleModal: modalActions.toggleModal,
  resetFilters: filtersActions.resetFilters
}

export default withTranslation()(connect(null, mapDispatchToProps)(NavDesktop))
