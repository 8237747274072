import { useEffect } from 'react'
import { connect } from 'react-redux'
import { googleMapsActions } from '../CookieConfirmation/state'
import { filter, last } from 'lodash'

export const CookieBanner = {
  googleServices: 'tischlerCookieBannerGoogleServices',
}

const CookieConfirmation = ({
  googleMapsDisableHandler,
  googleMapsEnableHandler
}) => {
  useEffect(() => {
    // Usercentrics CMP, GDPR for Google Maps
    // Listen to changes of Cookie consent banner
    typeof window !== 'undefined' &&
      window.addEventListener('UC_UI_CMP_EVENT', e => {
        let currentConsent

        if (e.currentTarget && e.currentTarget.dataLayer) {
          // Search in events for change consent
          const consentStatus = filter(e.currentTarget.dataLayer, {
            event: 'consent_status'
          })

          // Find latest state
          currentConsent = last(consentStatus)

          // If google maps enabled send information to redux
          if (currentConsent['Google Maps'] === true) {
            googleMapsEnableHandler()
          } else {
            googleMapsDisableHandler()
          }
        }
      })
  }, [])

  return false
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  googleMapsEnableHandler: googleMapsActions.googleMapsEnable,
  googleMapsDisableHandler: googleMapsActions.googleMapsDisable
}

export default connect(null, mapDispatchToProps)(CookieConfirmation)
