import React from 'react'
import { connect } from 'react-redux'
import {
  Item,
  Label,
  Popup,
  Responsive,
  Icon,
  Button,
  Header
} from 'semantic-ui-react'
import { Link } from '../../locales'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'

import { wishlistActions } from './state'
import { getFluidImage } from '../../utils/images'
import { tabletBreakpoint } from '../../utils/constants'
import './WishlistPopup.css'

class WishlistPopup extends React.Component {
  state = {
    animateHeart: false
  }

  componentDidUpdate (prevProps) {
    if (
      Object.values(this.props.wishlist).length !==
      Object.values(prevProps.wishlist).length
    ) {
      this.toggleAnimateHeart()
    }
  }

  toggleAnimateHeart () {
    this.setState({ animateHeart: true })
    setTimeout(() => this.setState({ animateHeart: false }), 250)
  }

  handleRemoveItem (slug) {
    this.props.wishlistRemoveItem(slug)
  }

  render () {
    const { wishlist, t, i18n } = this.props
    const { animateHeart } = this.state
    const wishlistItems = Object.values(wishlist)
    const wishlistCount = wishlistItems.length

    return (
      <Popup
        flowing
        hoverable
        wide='very'
        position='bottom right'
        horizontalOffset={0}
        on='click'
        verticalOffset={6}
        trigger={
          <Icon.Group style={{ cursor: 'pointer' }}>
            <i
              className={`icon red big ${
                wishlistCount ? 'ta-heart-filled' : 'ta-heart-contur-thin'
              } ${animateHeart ? 'animateHeart' : ''}`}
            />
            {wishlistCount > 0 && (
              <Label color='grey' size='mini' floating circular>
                {wishlistCount}
              </Label>
            )}
          </Icon.Group>
        }
      >
        <Popup.Content>
          <Header as='h3' color='red' dividing>
            {t('wishlist-view.header')}
          </Header>
          {wishlistCount ? (
            <Item.Group
              divided
              style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              {wishlistItems.map(({ id, url, title, country, image }) => (
                <Item key={id}>
                  <Responsive
                    as={Item.Image}
                    size='tiny'
                    minWidth={tabletBreakpoint}
                  >
                    <Img
                      loading='lazy'
                      fadeIn={false}
                      fluid={getFluidImage({
                        cloudinary: image,
                        maxWidth: 80,
                        ar: 1
                      })}
                    />
                  </Responsive>
                  <Item.Content verticalAlign='top'>
                    <Item.Extra style={{ marginTop: 0 }}>{country}</Item.Extra>
                    <Item.Header>
                      <Link to={url} lang={i18n.language}>
                        {title}
                      </Link>
                    </Item.Header>
                    <Item.Extra>
                      <div
                        onClick={() => this.handleRemoveItem(id)}
                        style={{ float: 'right', cursor: 'pointer' }}
                      >
                        {t('wishlist-view.remove')}
                      </div>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              ))}
            </Item.Group>
          ) : (
            <Header as='h4' textAlign='center'>
              {t('wishlist-view.empty')}
            </Header>
          )}
        </Popup.Content>
        {wishlistCount > 0 && (
          <Button
            as={Link}
            lang={i18n.language}
            to='/wishlist'
            fluid
            size='big'
            color='red'
            content={t('wishlist-view.offer')}
          />
        )}
      </Popup>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ wishlist }) => {
  return { wishlist }
}

const mapDispatchToProps = {
  wishlistRemoveItem: wishlistActions.wishlistRemoveItem
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WishlistPopup)
)
