import React from 'react'
import { connect } from 'react-redux'
import {
  Segment,
  Icon,
  List,
  Header,
  Input,
  Container,
  Grid,
  Responsive
} from 'semantic-ui-react'
import Img from 'gatsby-image'
import { tabletBreakpoint } from '../../utils/constants'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { Link } from '../../locales'
import { withTranslation, Trans } from 'react-i18next'

import { getFooterImage } from '../../utils/images'
import Modal from '../Modals/Modal'
import { modalActions } from '../Modals/state'
import { filtersActions } from '../Filters/state'

import {
  getSharedCategories,
  localizeLink,
  unescape
} from '../../utils/helpers'
import CookieConfirmation from '../CookieConfirmation/CookieConfirmation'

class Footer extends React.Component {
  state = {
    email: '',
    errorMsg: ''
  }

  handleInputChange = event => {
    this.setState({
      email: event.target.value
    })
  }

  handleNewsletterSignup = () => {
    const { i18n } = this.props

    const email = this.state.email

    if (email) {
      navigate(localizeLink('/newsletter?email=' + email, i18n.language))
    }
  }

  render () {
    const { i18n, t, resetFilters } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            allFirestoreCategory(
              filter: { type: { eq: "Themen" } }
              sort: { fields: [title], order: ASC }
            ) {
              edges {
                node {
                  id
                  title
                  service
                  type
                  lang
                }
              }
            }
            allFirestoreCountry(
              filter: { sortOrder: { ne: 0 } }
              sort: { fields: [sortOrder], order: ASC }
            ) {
              edges {
                node {
                  id
                  title
                  slug
                  countryRegionSlug
                  lang
                  sortOrder
                }
              }
            }
            allWordpressPage(sort: { fields: [menu_order], order: ASC }) {
              edges {
                node {
                  id
                  title
                  slug
                  menu_order
                  acf {
                    menu_footer
                    language
                    alt_nav_title
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const countries =
            data.allFirestoreCountry &&
            data.allFirestoreCountry.edges.filter(
              ({ node: { lang } }) => lang === i18n.language
            )
          const allCategories = data.allFirestoreCategory.edges.filter(
            ({ node: { lang } }) => lang === i18n.language
          )
          const categories = getSharedCategories(allCategories)
          const wpPages = data.allWordpressPage.edges
            ? data.allWordpressPage.edges.filter(
                ({ node: { acf, slug } }) => acf.language === i18n.language
              )
            : []

          return (
            <>
              <Segment inverted color='red' padded='very' vertical>
                <Container>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Header as='h2' inverted>
                          <Header.Content>
                            {t('footer.newsletter')}
                            <Header.Subheader>
                              {t('footer.newsletter-details')}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <Modal id='newsletter' okLabel='OK' size='tiny' />
                        <Responsive
                          as={Input}
                          minWidth={tabletBreakpoint}
                          name='email'
                          type='email'
                          size='large'
                          fluid
                          action={{
                            inverted: true,
                            labelPosition: 'right',
                            size: 'large',
                            icon: <i className='icon ta-arrow-1-right' />,
                            content: t('footer.sign-up'),
                            onClick: this.handleNewsletterSignup
                          }}
                          placeholder={t('footer.newsletter-placeholder')}
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          aria-label='newsletter'
                        />
                        <Responsive
                          as={Input}
                          maxWidth={tabletBreakpoint - 1}
                          name='email'
                          type='email'
                          size='medium'
                          fluid
                          action={{
                            inverted: true,
                            size: 'medium',
                            content: t('footer.sign-up'),
                            onClick: this.handleNewsletterSignup
                          }}
                          placeholder={t('footer.newsletter-placeholder')}
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          aria-label='newsletter'
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Segment>

              <Container>
                <Segment padded='very' vertical>
                  <Grid divided inverted stackable>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <Trans i18nKey={'footer.hours'}>
                          <Header as='h3' color='red'>
                            Wir beraten Sie gerne
                          </Header>
                          <p>
                            <b>Montag bis Freitag 10:00 – 17:00</b>
                          </p>
                        </Trans>
                        <p>
                          <Trans i18nKey='footer.address'>
                            Tischler Reisen AG
                            <br />
                            Partnachstraße 50
                            <br />
                            82467 Garmisch-Partenkirchen
                          </Trans>
                          <br />
                          <a href={`mailto:${t('footer.email')}`}>
                            {t('footer.email')}
                          </a>{' '}
                          <br />
                          <a href={`tel:${t('footer.phone')}`}>
                            {t('footer.phone')}
                          </a>
                        </p>
                        <p>© {new Date().getFullYear()} Tischler Reisen AG</p>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Grid.Column width={4}>
                          <Header as='h3' color='red'>
                            {t('footer.fav-destination')}
                          </Header>
                          <List link>
                            {countries &&
                              countries.map(
                                ({
                                  node: { id, title, slug, countryRegionSlug }
                                }) => (
                                  <List.Item key={id}>
                                    <Link
                                      to={`/country-regions/${countryRegionSlug}/destinations/${slug}`}
                                      lang={i18n.language}
                                    >
                                      {title}{' '}
                                      <i className='icon red ta-arrow-1-right' />
                                    </Link>
                                  </List.Item>
                                )
                              )}
                          </List>
                        </Grid.Column>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Header as='h3' color='red'>
                          {t('footer.trips')}
                        </Header>
                        <List link>
                          {categories.map(category => (
                            <List.Item key={category}>
                              <Link
                                to={`/country-regions/all/destinations/all?categories=${category}`}
                                state={{ sharedCategories: [category] }}
                                lang={i18n.language}
                              >
                                <span onClick={() => resetFilters()}>
                                  {category}{' '}
                                  <i className='icon red ta-arrow-1-right' />
                                </span>
                              </Link>
                            </List.Item>
                          ))}
                        </List>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Header as='h3' color='red'>
                          {t('footer.customerservice')}
                        </Header>
                        <List link>
                          {wpPages
                            .filter(
                              ({ node: { acf } }) =>
                                acf && acf.menu_footer === 'kundenservice'
                            )
                            .map(
                              ({
                                node: {
                                  id,
                                  slug,
                                  title,
                                  acf: { alt_nav_title }
                                }
                              }) => (
                                <List.Item key={id}>
                                  <Link to={`/${slug}`} lang={i18n.language}>
                                    {unescape(alt_nav_title) || unescape(title)}{' '}
                                    <i className='icon red ta-arrow-1-right' />
                                  </Link>
                                </List.Item>
                              )
                            )}
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>

              <Segment inverted className='darkgray' vertical>
                <Container>
                  <Grid columns={4} stackable>
                    {wpPages
                      .filter(
                        ({ node: { acf } }) =>
                          acf && acf.menu_footer === 'bottom'
                      )
                      .map(
                        ({
                          node: {
                            id,
                            slug,
                            title,
                            acf: { alt_nav_title }
                          }
                        }) => (
                          <Grid.Column
                            key={id}
                            textAlign='center'
                            verticalAlign='middle'
                          >
                            <List inverted link>
                              <List.Item key={id}>
                                <Link to={`/${slug}`} lang={i18n.language}>
                                  <p>
                                    {unescape(alt_nav_title) || unescape(title)}
                                  </p>
                                </Link>
                              </List.Item>
                            </List>
                          </Grid.Column>
                        )
                      )}
                  </Grid>
                </Container>
              </Segment>

              <Container>
                <Grid padded='vertically' textAlign='center'>
                  <Grid.Column mobile={5} tablet={5} computer={2}>
                    <a
                      href='https://www.facebook.com/tischlerreisen'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='tischler at Facebook'
                    >
                      <Icon name='facebook' size='huge' color='red' />
                    </a>
                  </Grid.Column>

                  <Grid.Column mobile={5} tablet={5} computer={2}>
                    <a
                      href='https://www.instagram.com/tischlerreisen/'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='tischler at Instagrram'
                    >
                      <Icon name='instagram' size='huge' color='red' />
                    </a>
                  </Grid.Column>

                  <Grid.Column mobile={16} tablet={16} computer={2} />
                  <React.Fragment>
                    <Grid.Column mobile={8} tablet={4} computer={2}>
                      <a
                        href='https://www.drv.de/'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='DRV'
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          alt='DRV'
                          fixed={getFooterImage({
                            cloudinary: 'footer/drv20_bqwumh'
                          })}
                        />
                      </a>
                    </Grid.Column>

                    <Grid.Column mobile={8} tablet={4} computer={2}>
                      <a
                        href='https://v-i-r.de/'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='VIR'
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          alt='VIR'
                          fixed={getFooterImage({
                            cloudinary: 'footer/vir_nxpyes'
                          })}
                        />
                      </a>
                    </Grid.Column>

                    <Grid.Column mobile={8} tablet={4} computer={2}>
                      <a
                        href='https://www.iata.org/'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='AIATA'
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          alt='AIATA'
                          fixed={getFooterImage({
                            cloudinary: 'footer/aiata_eulhjn'
                          })}
                        />
                      </a>
                    </Grid.Column>

                    <Grid.Column mobile={8} tablet={4} computer={2}>
                      <a
                        href='https://pata-germany.de/'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='PATA'
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          alt='PATA'
                          fixed={getFooterImage({
                            cloudinary: 'footer/pata-logo-89x60'
                          })}
                        />
                      </a>
                    </Grid.Column>

                    <Grid.Column mobile={8} tablet={4} computer={2}>
                      <a
                        href='https://goasia.de/'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='GO-ASIA'
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          alt='GO-ASIA'
                          fixed={getFooterImage({
                            cloudinary: 'footer/goasia-logo-89x60'
                          })}
                        />
                      </a>
                    </Grid.Column>
                  </React.Fragment>
                </Grid>
              </Container>

              <CookieConfirmation />
            </>
          )
        }}
      />
    )
  }
}
/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal,
  resetFilters: filtersActions.resetFilters
}

export default withTranslation()(connect(null, mapDispatchToProps)(Footer))
