import React, { Component } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import { Modal as SemanticModal, Dropdown, Input } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import firebase from 'firebase/app'
import 'firebase/firestore'

import Modal from './Modal'

import { modalActions } from './state'
import { localizeLink } from '../../utils/helpers'

class SearchModal extends Component {
  state = {
    options: [],
    isFetching: false
  }

  handleChange = (e, { value }) => {
    const { toggleModal, id } = this.props

    toggleModal(id)
    navigate(value)
  }

  handleSearchChange = (e, { searchQuery }) => {
    if (searchQuery.length < 3) {
      return false
    }

    const { i18n, t } = this.props

    const lang = i18n.language
    const firestore = firebase.firestore()
    firestore.settings({ timestampsInSnapshots: true })

    this.setState({ isFetching: true, close: false })

    firestore
      .collection('search')
      .where('lang', '==', lang)
      .get()
      .then(querySnapshot => {
        const results = []
        querySnapshot.forEach(doc => {
          let item = doc.data()

          if (item.title.toLowerCase().includes(searchQuery.toLowerCase())) {
            results.push(item)
          }
        })

        const options = results.map(
          ({ title, slug, countrySlug, countryRegionSlug, collection }) => {
            let value = ''
            let content = ''
            if (collection === 'countries') {
              value = localizeLink(`/countries/${slug}`, lang)
              content = (
                <p>
                  {title} <span>({t(`finder.collections.${collection}`)})</span>
                </p>
              )
            } else {
              value = localizeLink(
                `/country-regions/${countryRegionSlug}/destinations/${countrySlug}/${collection}/${slug}`,
                lang
              )
              content = (
                <p>
                  {title} <span>({t(`finder.collections.${collection}`)})</span>
                </p>
              )
            }

            return {
              id: slug,
              key: slug,
              text: title,
              value,
              content
            }
          }
        )

        this.setState({ options, isFetching: false })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  }

  render () {
    const { id, t } = this.props
    const { options, isFetching } = this.state

    return (
      <Modal id={id}>
        <SemanticModal.Description>
          <Input fluid aria-label='search'>
            <Dropdown
              fluid
              placeholder={t('finder.placeholder')}
              search
              selection
              searchInput={{ autoFocus: true }}
              minCharacters={3}
              className='inverted'
              icon='search'
              closeOnBlur={false}
              selectOnBlur={false}
              selectOnNavigation={false}
              options={options}
              onChange={this.handleChange}
              onSearchChange={this.handleSearchChange}
              disabled={isFetching}
              loading={isFetching}
              aria-label='search'
            />
          </Input>
        </SemanticModal.Description>
      </Modal>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/

const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(connect(null, mapDispatchToProps)(SearchModal))
